import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Form from '../components/Form';
import '../styles/layout.css';
import audrey from '../../static/audrey-wide.png';
import simultaantolken from '../../static/simultaantolken.jpg';
import verbindingstolken from '../../static/verbindingstolken.jpg';
import badge from '../../static/kudo-interpreter-badge.png';
import stempel from '../../static/stempel.jpg';
import sdl from '../../static/SDL.png';

export default () => (
  <div>
    <Helmet>
      <title>B&D – Audrey Fromont</title>
      <meta
        name="description"
        content="Beëdigd vertaler, beëdigd tolk, conferentietolk."
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="RSI, Kudo, Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua Qua
vertaler + tandheelkunde, SDL Trados, Translator + dentistry, dental translator, Dutch"
      />
    </Helmet>
    <Layout>
      <div className="columns">
        <div className="column is-full">
          <img src={audrey} />
        </div>
      </div>
      <div className="container is-fluid">
        <hr />
        <nav className="level">
          <h3
            style={{ fontFamily: 'Dancing Script' }}
            className="introItem level-item has-text-centered is-size-6-mobile"
          >
            Traductora jurada - Intérprete jurada - Intérprete de conferencias
          </h3>
        </nav>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">Interpretación simultánea</h3>
            <p className="is-size-7-mobile">
              <strong>
                RSI: Kudo, Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua
                Qua, …
              </strong>
            </p>
            <img className="mb-3" src={badge} alt="" width="100" />
            <p className="is-size-7-mobile">
              La traducción simultánea se efectúa de manera oral con asistencia
              del material necesario, como por ejemplo, micrófonos, audífonos y
              eventualmente una cabina. De esta manera, se puede seguir una
              conversación al mismo tiempo en el idioma original y en una
              traducción. Puede solicitar mis servicios para traducciones
              simultáneas en neerlandés y en inglés. Hace ya casi 20 años que me
              he ganado la confianza de oficinas de interpretación tanto en
              Bélgica como en el extranjero.
            </p>
            <img src={simultaantolken} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Interpretación susurrada </h3>
            <p className="is-size-7-mobile">
              Para este tipo de interpretación no se requiere la asistencia de
              material técnico. Como intérprete (jurado) traduzco oralmente en
              exámenes de conducción, al igual que interrogatorios para la
              policía, la inspección social, el servicio de tutela, etc. Al
              respecto, se puede poner en contacto conmigo para los siguientes
              idiomas: neerlandés, francés, inglés, español y chino (mandarín).
            </p>
            <img src={verbindingstolken} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">
              Traducciones juradas (por escrito)
            </h3>
            <p className="is-size-7-mobile">
              ¿Le piden que presente una traducción jurada? A través del
              formulario de contacto puede solicitar traducciones juradas en
              neerlandés, inglés, francés y español. He prestado juramento ante
              los Tribunales de Amberes, Bruselas, Malinas, Lovaina, Veurne y
              Gante. Por consiguiente, y cuando fuere necesario, puede legalizar
              mi firma en todas estas ciudades. En el extranjero, a veces se
              solicita que una traducción jurada esté provista de una Apostilla.
              Haga clic{' '}
              <a className="is-lowercase" href="http://diplomatie.belgium.be">
                {' '}
                aquí{' '}
              </a>{' '}
              para una información más detallada al respecto.
            </p>
            <img src={stempel} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">
              Traducciones en idioma neerlandés de tipo general
            </h3>
            <p className="is-size-7-mobile">
              <strong style={{ color: 'white' }}>
                Traducciones en idioma neerlandés de tipo general, comercial,
                administrativo, notarial, jurídico, financiero. En el pasado
                también realicé traducciones de turismo, moda y diseño y de
                odontología.
              </strong>
            </p>
            <p className="is-size-7-mobile">
              No importa si tiene que traducir un documento de muchas páginas o
              solamente un párrafo. Envíe su solicitud a través del formulario
              de contacto, y recibirá a la brevedad posible una tarifa y un
              plazo de entrega.
            </p>
            <p className="is-size-7-mobile">
              Las traducciones hacia el neerlandés (mi idioma materno) las hago
              yo misma. Para las traducciones hacia otros idiomas, encargo su
              relectura a mis colegas cuyo idioma materno sea inglés, español o
              francés.
            </p>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">Miembro</h3>
            <p className="is-size-7-mobile">
              Lextra Lingua – Asociación profesional de traductores e
              intérpretes jurados
            </p>
            <p className="is-size-7-mobile">
              Centre for Translation Studies (CETRA) [Centro para Estudios de
              Traducción]
            </p>
            <p className="is-size-7-mobile">
              Belgian Association of Anglicists in Higher Education (BAAHE)
              [Asociación belga de anglicistas en la Educación Superior]
            </p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Diplomas</h3>
            <p className="is-size-7-mobile">
              Máster en traducción. Universidad de Amberes
            </p>
            <p className="is-size-7-mobile">
              Máster en interpretación. Vlekho Bruselas
            </p>
            <p className="is-size-7-mobile">
              Doctorada en lingüística aplicada: ciencia de la traducción.
              Universidad Católica de Lovaina
            </p>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">Contacto</h3>
            <p className="is-size-7-mobile">Astene (Deinze)</p>
            <p className="is-size-7-mobile">audrey.fromont@gmail.com</p>
            <p className="is-size-7-mobile">+32(0)475 29 20 28</p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">Formulario de contacto</h3>
            <Form
              firstLabel="Nombre:"
              secondLabel="E-mail:"
              thirdLabel="El mensaje:"
              btnLabel="Enviar"
              firstOption="- particular"
              secondOption="- empresa / asociación / agencia"
              thirdOption="- servicio de policía"
              fourthOption="- colaborador universitario"
              selectLabel="Usted me contacta como:"
            />
          </div>
        </div>
      </div>
    </Layout>
  </div>
);
